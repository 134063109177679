.header_mid {
   z-index: 50;
   position: relative;
}

.header_mid .logo_slide {
  padding: 25px 0;
  height: 150px;
  overflow: hidden;
  position: relative;
  width:100%;
  margin: auto;
  margin-top: 90px;
}
.loop, .loop-clone {
  display: block;
  -width: 200%;
  width: 1350px;
  height: 100px;
  position: absolute;
  overflow: hidden;
}
.loop {
  animation: marquee 20s linear infinite;
  margin: 0px;
  padding: 0px;
}
.loop-clone {
  animation: marquee-clone 20s linear infinite;
  margin: 0px;
  padding: 0px;
}

.header_mid .logo_slide .logo_item {
  
  height: 100px;
  padding: 0 90px 0 0;
  float: left;
}

 img{
  width: 100px;
  height: 100px;
}
@keyframes marquee {
  0% { transform: translateX(0); }
  20% { transform: translateX(-20%); }
  40% { transform: translateX(-40%); }
  60% { transform: translateX(-60%); }
  80% { transform: translateX(-80%); }
  100% { transform: translateX(-100%); }
}

@keyframes marquee-clone {
  0% { transform: translateX(103%); }
  20% { transform: translateX(80%); }
  40% { transform: translateX(60%); }
  60% { transform: translateX(40%); }
  80% { transform: translateX(20%); }
  100% { transform: translateX(0); }
}


ul.loop, ul.loop-clone {
  list-style-type: none; /* Remove bullet points */
}

@media only screen and (max-width: 928px) {
   img{
    width: 50px;
    height: 50px;
  }
  .header_mid .logo_slide .logo_item {
  
  height: 100px;
  padding: 0 50px 0 0;
  float: left;
}
.loop, .loop-clone {
  display: block;
  -width: 200%;
  width: 600px;
  height: 100px;
  position: absolute;
  overflow: hidden;
}
.loop {
  animation: marquee 10s linear infinite;
  margin: 0px;
  padding: 0px;
}
.loop-clone {
  animation: marquee-clone 10s linear infinite;
  margin: 0px;
  padding: 0px;
}

.header_mid .logo_slide {
  
  margin-top: 30px;
}

}