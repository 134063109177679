/* MouseTrail.css */
.mouse-trail {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -5;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.mouse-trail.active {
  opacity: 1;
}

.trail-dot {
  position: absolute;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: linear-gradient(to right, #ffcc00, #ff6600); /* Градиентный фон от желтого к оранжевому сбоку */
  filter: blur(20px);
  transform: translate(-50%, -50%);
}

.trail-dot + .trail-dot {
  margin-left: -10px;
}

