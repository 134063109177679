/* Roles.css */
.h1rol{
  margin-bottom: 60px;
  color: white;
  font-size: 98px;
  margin-top: 130px;
  font-family: 'Kharkiv Tone', sans-serif;
}
.container {
  background-color: transparent; /* Set the background color of the container to transparent */
  border: none;
   border-radius: none;
   z-index: 50;
   position: relative;

}
.col-md-41{
  width: 31%;
  margin-top: 0px!important;
   font-family: 'Helvetica Ultra Light', sans-serif;
   font-weight: 600;
}

.col-md-41 .card{
  margin-bottom: 80px!important;
  
}

.col-md-1{
  width: 7%;
}
.col-md-41 p{
   display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 19px;
  color: white;
  padding-top: 0px;
  font-family: 'Helvetica Ultra Light', sans-serif;
   letter-spacing: 1px;
   font-weight: 600;
}

.col-md-32{
  margin: 0px!important;
  width: 28%!important;
  margin-right: 50px!important;
  font-family: 'Helvetica Light', sans-serif;
   font-weight: 400;
}

.col-md-31{
  margin: 0px!important;
  width: 28%!important;
  font-family: 'Helvetica Light', sans-serif;
   font-weight: 400;
}


.txt{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 26px!important;
  padding-top: 0px!important;
  padding-bottom: 20px!important;
  color: white;
  margin-right: 25px;
}

.txt1{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px!important;
  padding-top: 15px;
  padding-bottom: 15px;
  color: white;
  font-family: 'Helvetica Ultra Light', sans-serif;
   letter-spacing: 1px;
   font-weight: 400;
}
/* You may need to add more specific selectors based on your existing CSS structure */
.card,
.card-header,
.card-body {
  background-color: transparent !important; /* Set the background color of the Card components to transparent */
  border: none;
  border-radius: 0px;
  padding: 0px!important;
}
.card-header{
   border-bottom: 1px solid white;
   padding: 0px!important;

}

/* Additional styling if needed */
.rotate-icon {
  transition: transform 0.3s ease-in-out;
}

.rotatet {
  transition: transform 0.3s ease-in-out;
  color: white;
   font-family: 'Helvetica Ultra Light', sans-serif;
   letter-spacing: 1px;
   font-weight: 600;
    font-size: 35px!important;
}
.rotatet.rotate-icon {
  transform: rotate(45deg);
}

.card{
  margin-bottom: 60px!important;
}
.row{
  margin-bottom: 0px!important;
  height: 70vh;
}

.card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%; /* Забезпечуємо, щоб блок займав 100% висоти */
}

.card-body p {
  margin: 0; /* Забезпечуємо, щоб не було зайвого відступу */
}

.card-body p:first-child {
  margin-bottom: auto; /* Прилеплюємо перший абзац до верху */
}

.card-body p:last-child {
  margin-top: auto; /* Прилеплюємо другий абзац до низу */
}



@media only screen and (max-width: 928px) {
  .h1rol{
  font-size: 47px;
  margin-top: 40px;
  margin-bottom: 40px;
}

.col-md-41{
  width: 100%;
  
}
.col-md-41 .card{
  margin-bottom: 60px!important;
}
.col-md-41 p{
  font-size: 16px;
}

.col-md-32{

  width: 100%!important;

}

.col-md-31{

  width: 100%!important;

}


.txt{

  font-size: 20px!important;
}

.txt1{

  font-size: 16px!important;
  font-family: 'Helvetica Light', sans-serif;

}

.container{
     width: 84vw;
    margin-left: 30px;
    margin-right: 30px;
    padding: 0px;
}
.row{
  margin-bottom: 60px!important;
  height: fit-content;
}

}