#PP h1{
	font-size: 90px;
}
#PP p{
	font-size: 30px;
}
#PP h2{
	font-size: 30px;
}
#PP h3{
	font-size: 30px;
}
#PP .lii{
	font-size: 30px;
}

@media only screen and (max-width: 928px) {
	#PP h1{
	font-size: 40px;
	}
	#PP p{
		font-size: 13px;
	}
	#PP h2{
		font-size: 13px;
	}
	#PP h3{
		font-size: 13px;
	}
	#PP li{
	font-size: 13px;
}
}