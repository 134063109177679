.menublob {
  position: sticky;
  top: 30px;
  z-index: 1550;
  right: 0;
  text-align: right;
  margin-right: 60px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: flex-end;
}
button {
  padding: 12px 10px;
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  color: #fff;
  border: none;
  background-color: rgba(39, 39, 39, 0.5) !important; /* Пример цвета с прозрачностью 0.5 */
  backdrop-filter: blur(1px); /* Применяем размытие */
  z-index: 1600;
  border: 1px solid white;
  cursor: pointer;
  border-radius: 8px 8px 8px 8px;
  width: 60px;
  right: 0;
  align-self: flex-end;
  margin-bottom: 10px;
}

.ulc {
  position: absolute;
  top: 65px;
  padding: 10px 10px 10px 0px;
  background-color: rgba(39, 39, 39, 0.7) !important; /* Пример цвета с прозрачностью 0.5 */
  backdrop-filter: blur(5px); /* Применяем размытие */
  z-index: 1600;
  border: 1px solid white;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 0px;
  height: 0px;
  border-radius: 8px;
  align-items: center;
  opacity: 0;
  right: 0;
  align-self: flex-end;
}

.lic {
  list-style: none;
  z-index: 1601;
  margin: 0;
  padding: 10px 0;
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 17px;
  color: #fff;
  right: 0;
}

.text-light {
  text-decoration: none;
}

.imgforburger {
  height: 30px;
  width: 35px;
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0)); /* Начальная тень: отсутствует */
  transition: filter 0.3s ease; /* Анимация перехода для плавного изменения тени */
}

.imgforburger:hover {
  filter: drop-shadow(0 0 5px rgba(255, 255, 255, 1)); /* Показываем тень при наведении */
}
