.AboutUsBlock {
  font-family: 'Kharkiv Tone', sans-serif;
  text-align: center;
  position: relative;
  position: relative;
  text-align: left;
  display: flex;
  width: 100vw;
  z-index: 40;
}

.AboutUsBlock-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-left: 60px;
  margin-right: 60px;
  height: 200vh;
  width: 100vw;
  color: white;
  position: relative;
  z-index: 50;
}

.AboutUsSec-header{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-left: 60px;
  margin-right: 60px;
  height: 200vh;
  width: 100vw;
  color: white;
  position: relative;
  z-index: 50;
}
.AboutUsBlock-header h1{
  font-family: 'Kharkiv Tone', sans-serif;
  font-size: 98px!important;
  margin-bottom: 40px;
  margin-top: 60px;
}
.AboutUsBlock-header p{
  align-items: end;
  text-align: end;
  align-self: flex-end;
  font-size: 50px!important;
  width: 70%;
  margin-bottom: 60px;
  height: 300px;
}

.p1{
  font-size: 50px!important;
  text-align: left!important;
   align-self: flex-start!important;
   width: 85%!important;
   margin-top: 120px;
   margin-bottom: 0px!important;
   height: fit-content;
}

.AboutUsBlock-header .st{
  display: flex;
  flex-direction: column;
  top:-40px;
}

canvas {
  display: block;
}

.scroll-to-top-btn {
   margin-top: 60px;
   z-index: 1005;
  position: relative;
  align-self: center;
  cursor: pointer;
  font-size: 75px;
  color: #FF8A1E; /* Change the color as needed */
}

@keyframes arrowAnimation {
  0% {
    transform: scale(1); /* Начальный размер */
  }
  100% {
    transform: scale(1.5); /* Размер в 2 раза больше */
  }
}

@media (min-width: 416px) and (max-width: 928px){
  .AboutUsBlock-header {
    min-height: 55vh;
    height: fit-content;
    
 }
 .AboutUsSec-header{
   min-height: 45vh;
    height: fit-content;
  }
}
@media (max-width: 928px){
  .AboutUsBlock-header {
    
    margin-left: 30px;
  margin-right: 30px;
 
}

.AboutUsSec-header{
  
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-left: 30px;
  margin-right: 30px;
  
  width: 100vw;
  color: white;
  position: relative;
  z-index: 50;
}

  .AboutUsBlock-header h1{
  font-family: 'Kharkiv Tone', sans-serif;
  font-size: 47px!important;
  margin-bottom: 60px;
  margin-top: 130px;

}
.AboutUsBlock-header p{
  align-items: end;
  text-align: end;
  align-self: flex-end;
  font-size: 25px!important;
  width: 90%;
  margin-bottom: 0px;
  height: fit-content;
}

.p1{
  font-size: 25px!important;
  text-align: left!important;
   align-self: flex-start!important;
   width: 95%!important;
   margin-bottom: 0px!important;
   margin-top: 20px;
   height: fit-content;
}

}

@media (min-width: 0px) and (max-width: 380px){
   .AboutUsBlock-header {
        min-height: 80vh;
    height: fit-content;
  }
  .AboutUsBlock-header p{
    height: fit-content;
}
.AboutUsSec-header{
   min-height: 55vh;
    height: fit-content;
  }

}

@media (min-width: 390px) and (max-width: 400px){
  .AboutUsBlock-header {
     min-height: 65vh;
    height: fit-content;
  }
  .AboutUsSec-header {
     min-height: 57vh;
    height: fit-content;
  }
   .AboutUsBlock-header p1{
    height: fit-content;
}
}

@media (min-width: 401px) and (max-width: 415px){
  .AboutUsBlock-header {
     min-height: 55vh;
    height: fit-content;
  }
  .AboutUsSec-header {
     min-height: 50vh;
    height: fit-content;
  }
   .AboutUsBlock-header p1{
    height: fit-content;
}
}