::placeholder {
  color: white; /* Set the color you want for the placeholder text */
}
.h1con{
  position: relative;
  margin-left: 60px;
  width: 55%;
  color: white;
  font-family: 'Kharkiv Tone', sans-serif;
  font-size: 98px!important;
  margin-bottom: 60px;
  margin-top: 130px;
  z-index: 1050;

}
.file-selected {
  background-color: rgba(255, 255, 255, 0.2);
    z-index: 1050;
}
.btn1{
    font-size: 20px;
    font-family: 'Helvetica Light', sans-serif;
    border: 1px solid white;
      z-index: 1050;
  }
  .pincont{
    padding-top: 30px;
    font-size: 15px;
  }
@media (max-width: 928px) {
  .h1con{
    font-size: 47px!important;
    margin-left: 0px;
    width:100%;
    margin-top: 60px;
    margin-bottom: 40px;
  }

  .contain1{
    margin-left: 30px;
    margin-right: 30px;
    width: 86vw;
  }
  .rt1{
     position: relative;
      z-index: 1050;
    flex-direction: column;
  }
  .rt2{
     position: relative;
      z-index: 1050;
    width: 100%;
    margin-bottom: 10px;
  }
  .f1{
    width: 100%!important;
  }
  .pd{
    height: 150px!important;
  }
  .cic{
    margin-top: 10%!important;
  }
  .spz{
    margin-bottom: 15%;
  }
  .btn1{
    font-size: 14px;
  }

  .pincont{
    padding-top: 0px;
    font-size: 13px;
  }

}
